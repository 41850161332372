import { Link } from 'gatsby';
import React from 'react';
import caretRightIcon from '../../images/library/caretRight.svg';
import collectionIcon from '../../images/library/collection.svg';
import { LibraryCollection } from '../../types';
import styles from './LibraryCollectionsList.module.scss';

interface LibraryCollectionsListProps {
  collections: LibraryCollection[];
}

const LibraryCollectionsList: React.SFC<LibraryCollectionsListProps> = ({
  collections,
}) => (
  <>
    {collections.map((collection) => (
      <Link key={collection.url} to={collection.url} className={styles.post}>
        <div className={styles.picture}>
          <img src={collectionIcon} alt="" role="presentation" />
        </div>
        <div className={styles.info}>
          <div className={styles.title}>{collection.name}</div>
          <div>{collection.description}</div>
        </div>
        <div className={styles.caret}>
          <img src={caretRightIcon} alt="" role="presentation" />
        </div>
      </Link>
    ))}
  </>
);

export default LibraryCollectionsList;
