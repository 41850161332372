import React from 'react';
import collectionIcon from '../../images/library/collection.svg';
import { LibraryCategory, LibraryCollection } from '../../types';
import LibraryBreadcrumb from './LibraryBreadcrumb';
import styles from './LibraryCategoryOrCollectionPage.module.scss';
import LibraryCollectionsList from './LibraryCollectionsList';
import LibraryContentGrid from './LibraryContentGrid';
import LibraryPostsList from './LibraryPostsList';
import getAllCategoryPosts from './getAllCategoryPosts';

interface LibraryCategoryOrCollectionPageProps {
  category: LibraryCategory;
  collection: LibraryCollection | undefined;
}

const LibraryCategoryOrCollectionPage: React.SFC<
  LibraryCategoryOrCollectionPageProps
> = ({ category, collection }) => {
  const allPosts = collection
    ? collection.posts
    : getAllCategoryPosts(category);

  return (
    <div className={styles.container}>
      <div className={styles.back}>
        <LibraryBreadcrumb category={collection && category} />
      </div>
      <h1 className={styles.header}>
        {collection ? (
          <>
            <img
              src={collectionIcon}
              alt=""
              role="presentation"
              className={styles.collectionIcon}
            />
            {collection.name}
          </>
        ) : (
          category.name
        )}
      </h1>
      {collection ? (
        <div className={styles.collectionDescription}>
          {collection.description}
        </div>
      ) : (
        <>
          {category.collections.length > 0 && (
            <>
              <div className={styles.sectionHeader}>Collections</div>
              <div className={styles.desktopPosts}>
                <LibraryContentGrid contents={category.collections} />
              </div>
              <div className={styles.mobilePosts}>
                <LibraryCollectionsList collections={category.collections} />
              </div>
            </>
          )}
          <div className={styles.sectionHeader}>Examples &amp; Templates</div>
        </>
      )}
      <div className={styles.desktopPosts}>
        <LibraryContentGrid contents={allPosts} />
      </div>
      <div className={styles.mobilePosts}>
        <LibraryPostsList posts={allPosts} />
      </div>
      {collection?.long_description ? (
        <div className={styles.collectionLongDescription}>
          <h3 className={styles.collectionLongDescriptionTitle}>
            {collection.name} by Slab
          </h3>
          <p className={styles.collectionLongDescriptionText}>
            {collection.long_description}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default LibraryCategoryOrCollectionPage;
